@import url("https://fonts.googleapis.com/css2?family=Share+Tech&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Share+Tech&display=swap");
body {
    margin: 0;
    padding: 0;
}

* {
    font-family: "Roboto", sans-serif;
    transition: width 0.5s, height 0.5s;
}

.App {
    width: 100vw;
    height: 100vh;

    display: flex;
}

.TopNav {
    width: 100vw;
    height: 8vh;

    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid #ccc;
}

#LogoContainer {
    width: 20%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 1px solid #ccc; */
}

#Logo {
    font-family: "Share Tech", sans-serif;
    font-weight: 400;
    font-size: 28px;
    font-style: normal;
}

#SearchBarContainer {
    width: 35%;
    height: 30px;

    display: flex;
    flex-direction: row;

    border-radius: 10px;
    border: 1px solid #000;
}

#SearchInput {
    width: 100%;
    height: 100%;

    font-size: 14px;

    background-color: transparent;
    border: none;
    outline: none;
    /* border: 1px solid #000; */
}

#SearchInput::placeholder {
    color: #000;
}

#SearchLogo {
    width: 8%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #000; */
}

#SearchLogo img {
    width: 60%;
    height: auto;
}

#FunctionContainer {
    width: 45%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* border: 1px solid #000; */
}

#FunctionContainer button {
    cursor: pointer;
    margin-right: 12px;
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    /* border: 1px solid #000; */
    background-color: transparent;
}

#FunctionContainer img {
    width: 80%;
    height: auto;
}

.SideNav {
    position: absolute;
    top: 8vh;
    left: 0;

    width: 5vw;
    height: 98vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-right: 1px solid #ccc;
}

.SideNav button {
    margin-top: 15px;

    width: 40px;
    height: 40px;

    background-color: transparent;

    border: none;
    /* border: 1px solid #000; */
}

.SideNav img {
    width: 80%;
    height: auto;
}

.SmallSideNav {
    position: absolute;
    top: 8vh;
    right: 0;

    width: 8vw;
    height: 98vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #fff;

    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}

.SmallSideNav button {
    margin-top: 15px;

    width: 40px;
    height: 40px;

    background-color: transparent;

    border: none;
    /* border: 1px solid #000; */
}

.SmallSideNav img {
    width: 80%;
    height: auto;
}

.BodyContainer {
    position: absolute;
    top: 8vh;
    left: 5vw;

    width: 95vw;
    height: 92vh;

    display: flex;
    flex-direction: column;
}

#Title {
    margin-left: 20px;
    font-size: 26px;
    font-weight: 700;
}

#Categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#CategoriesItem {
    cursor: pointer;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;

    width: 200px;
    height: 250px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#CategoriesImage {
    width: 100%;
    height: 85%;

    display: flex;
    align-items: center;
    justify-content: center;

    /* border: 1px solid #000; */
}

#CategoriesImage img {
    width: 90%;
    height: auto;
}

#CategoriesItemTitle {
    width: 100%;
    height: 15%;

    display: flex;
    align-items: center;
    justify-content: center;

    /* border: 1px solid #000; */
}

#CategoriesItemTitle p {
    font-weight: 300;
    font-size: 18px;
}

#BestSelling {
}

@media screen and (max-width: 1210px) {
    #FunctionContainer img {
        width: 60%;
        height: auto;
    }
}

@media screen and (max-width: 850px) {
    .BodyContainer {
        position: absolute;
        top: 8vh;
        left: 0;

        width: 95vw;
        height: 92vh;

        display: flex;
        flex-direction: column;
    }

    .SideNav {
        width: 0;
        width: 0;

        overflow: hidden;
    }

    #Order {
        position: absolute;
        left: 0;

        width: 0;
        height: 0;

        overflow: hidden;
    }

    #Order img {
        display: none;
    }

    #SearchInput {
        font-size: 14px;
    }

    #SearchLogo img {
        width: 80%;
    }

    #Logo {
        font-size: 24px;
    }
}

@media screen and (max-width: 635px) {
    .BodyContainer {
        position: absolute;
        top: 8vh;
        left: 0;

        width: 95vw;
        height: 92vh;

        display: flex;
        flex-direction: column;
    }

    #Logo {
        font-size: 20px;
    }

    #Order {
        position: absolute;
        left: 0;

        width: 0;
        height: 0;

        overflow: hidden;
        /* display: none; */
    }

    #Order img {
        display: none;
    }

    #SearchBarContainer {
        margin-left: 5px;
        width: 35%;
        height: 25px;

        display: flex;
        flex-direction: row;

        border-radius: 10px;
        border: 1px solid #000;
    }

    #SearchInput {
        width: 100%;
        height: 100%;

        font-size: 10px;

        background-color: transparent;
        border: none;
        outline: none;
        /* border: 1px solid #000; */
    }

    #SearchLogo {
        width: 10%;
        margin-left: 1px;
    }
    #SearchLogo img {
        width: 100%;
    }

    #FunctionContainer button {
        width: 40px;
        height: 40px;
    }

    .SmallSideNav button {
        width: 30px;
    }

    #Title {
        font-size: 20px;
    }

    #CategoriesItem {
        cursor: pointer;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;

        width: 100px;
        height: 150px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #CategoriesItemTitle p {
        font-weight: 300;
        font-size: 12px;
    }
}
